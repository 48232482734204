import React, { FC } from "react";
import moment from "moment/moment";
import { CkIcon } from "../../../CkUI";

interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
  onClick?: Function;
}
export const PointView: FC<IStateProgress> = ({
  id,
  title,
  date,
  passed,
  onClick,
}) => (
  <div className="dots-container" key={id}>
    <div
      className={["dot", ...(passed ? ["passed"] : [])].join(" ")}
      style={{
        color: `var(--${id}-color)`,
      }}
    >
      <CkIcon name="checkmark-rounded" />
    </div>
    <div className="info">
      <p
        className={onClick && "clickable"}
        onClick={onClick ? () => onClick() : undefined}
      >
        {title}
      </p>
      {date && (
        <div>
          <p className="date">
            {moment(date.endsWith("Z") ? date : `${date}Z`)
              .format("DD/MMMM/YYYY")
              .replace(/\b\w/g, (l) => l.toUpperCase())}
          </p>
          <p className="date">
            {moment(date.endsWith("Z") ? date : `${date}Z`)
              .format("h:mm a")
              .toLocaleUpperCase()}
          </p>
        </div>
      )}
    </div>
  </div>
);
