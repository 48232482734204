import React, { FC } from "react";
import { Divider } from "antd";
import { PointView } from "./PointView";

interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
  onClick?: Function;
}
interface IProgress {
  state: Array<IStateProgress>;
  percent: number;
}

export const AppointmentProgress: FC<IProgress> = ({ state }) => (
  <div className="appo-progress">
    <div className="divider-size">
      <Divider type={"horizontal"} />
    </div>
    <div className="dots-direction">
      {state.map((value) => PointView(value))}
    </div>
  </div>
);
