import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { ConfirmationModal } from "../Modals/ConfirmationModal";
import { CalendarDeleteIcon } from "../../../assets/SvgIcons";
import QuotationFormModal from "../Modals/Appointments/QuotationFormModal";
import DiagnosticFormModal from "../Modals/Appointments/DiagnosticFormModal";
import NEWReceptionFormModal from "../Modals/Appointments/NEWReceptionFormModal";
import QuotationApprovalModal, {
  TContactChannels,
} from "../Modals/Appointments/QuotationFormModal/QuotationApprovalModal";
import ConfirmAppointmentModal from "../Modals/Appointments/ConfirmAppointmentModal";
import { AppointmentDetailsHeader } from "./AppointmentDetailsHeader";
import { ProductRecommendationModal } from "../Modals/ProductRecommendationModal";
import { AppointmentDetailsProgress } from "./AppointmentDetailsProgress";
import { AppointmentDetailsActions } from "./AppointmentDetailsActions";
import { AppointmentDetailsTable } from "./AppointmentDetailsTable";
import { RescheduleModal } from "./RescheduleModal";
import { Product } from "../../models/oli";
import {
  Appointment,
  checkNotification,
  completeAppoinment,
  Service,
  updateAppointment,
  VehicleCatalog,
  WorkHours,
  WorkshopService,
} from "shared";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AppointmentAPI,
  CatalogAPI as CatalogsAPI,
} from "../../Services/axios";
import { CurrentUser } from "../../../context/UserContext";
import { fetchAllCoworkers } from "shared/src/services/userAPI";
import { gtmAddLayer, tierCodeToDesc } from "../../Utilities";
import {
  getAppointment,
  getAppointmentError,
  getAppointmentLoading,
} from "../../../stores";
import QuotationInfoModal from "../Modals/Appointments/QuotationFormModal/QuotationInfoModal";
import { WheelLoader } from "../Loaders";
import { CkMessage } from "../../../CkUI";
import "./styles.css";

interface IProps {
  dataInfo: any;
  workshopId: string;
  workshopServiceList: WorkshopService[];
  packagesList: any[];
  hours: WorkHours[];
  reloadAppointmentData: Function;
  product: any[];
  setProduct: Function;
  setProductSize: Function;
  productSize: number;
  showProductRecModal: boolean;
  setShowProductRecModal: Function;
}
interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
  onClick?: Function;
}
interface ISubtotal {
  label: string;
  amountToDiscount: number;
  subtotal: number;
}
interface IQuotationLine {
  id: number;
  label: string;
  description: string;
  pricePerUnit: number;
  numberOfUnits: number;
  discount: number;
  amountToDiscount: number;
  lineTotal: number;
}
interface IQuotationItem {
  id: number;
  isApproved: boolean;
  lines: IQuotationLine[];
}
type TQuotationStatus = "DRAFT" | "WAITING_APPROVAL" | "APPROVED";
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const exitState = [
  {
    id: "RECEP",
    passed: false,
    title: "Recibido",
    date: "",
  },
  {
    id: "DIAG",
    passed: false,
    title: "Diagnositcado",
    date: "",
  },
  {
    id: "QUOT",
    passed: false,
    title: "Cotizado",
    date: "",
  },
  {
    id: "PROG",
    passed: false,
    title: "Reparando",
    date: "",
  },
  {
    id: "READY",
    passed: false,
    title: "Terminado",
    date: "",
  },
  {
    id: "CLOS",
    passed: false,
    title: "Entregado",
    date: "",
  },
];
const initialProgress = [
  {
    id: "RECEP",
    passed: false,
    title: "Recibido",
    date: "",
  },
  {
    id: "DIAG",
    passed: false,
    title: "Diagnositcado",
    date: "",
  },
  {
    id: "QUOT",
    passed: false,
    title: "Cotizado",
    date: "",
  },
  {
    id: "PROG",
    passed: false,
    title: "Reparando",
    date: "",
  },
  {
    id: "READY",
    passed: false,
    title: "Terminado",
    date: "",
  },
  {
    id: "CLOS",
    passed: false,
    title: "Entregado",
    date: "",
  },
];
interface IappointmentHistoryStatus {
  statusCode:
    | "CREAT"
    | "PEND"
    | "APPO"
    | "RECEP"
    | "DIAG"
    | "QUOT"
    | "PROG"
    | "READY"
    | "CANCE"
    | "CLOS";
  statusModifiedDate: string;
}

let msgID: string | number | undefined = undefined;

export const AppointmentDetails: React.FC<IProps> = ({
  dataInfo,
  workshopId,
  workshopServiceList,
  packagesList,
  hours,
  reloadAppointmentData,
  product,
  setProduct,
  setProductSize,
  productSize,
  showProductRecModal,
  setShowProductRecModal,
}) => {
  const windowSize = window.innerWidth;
  const dispatch = useDispatch<any>();
  const user = useContext(CurrentUser);
  const loadingAppointment: boolean = useSelector(getAppointmentLoading);
  const [error, errorMessage]: [boolean, string?] =
    useSelector(getAppointmentError);
  const appointmentEdited = useSelector(getAppointment);

  const [loadingReschedule, setLoadingReschedule] = useState<boolean>(false);

  const [invoiceNo, setInvoiceNo] = useState<string>("");
  const [reasons, setReasons] = useState<string>("");
  const [vehicleBrands, setVehicleBrands] = useState<VehicleCatalog[]>([]);
  const [vehicleModels, setVehicleModels] = useState<VehicleCatalog[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [openQuotationInfoModal, setOpenQuotationInfoModal] =
    useState<boolean>(false);

  const [isClientDriver, setIsClientDriver] = useState<boolean>(false);
  const [loadingAppointmentTracking, setLoadingAppointmentTracking] =
    useState<boolean>(true);
  const [isReceptionFormCompleted, setIsReceptionFormCompleted] =
    useState<boolean>(false);
  const [isDiagnosticsFormCompleted, setIsDiagnosticsFormCompleted] =
    useState<boolean>(false);
  const [isQuotationFormCompleted, setIsQuotationFormCompleted] =
    useState<boolean>(false);

  const [progressState, setProgressState] =
    useState<Array<IStateProgress>>(initialProgress);
  const [progressPercent, setProgressPercent] = useState<number>(0);

  const [quotationItems, setQuotationItems] = useState<any[]>([]);
  const [packages, setPackages] = useState<any[]>([]);
  const [selectedPackageSet, setSelectedPackageSet] = useState<any[]>([]);
  const [servicesSelected, setServicesSelected] = useState<any[]>([]);
  const [servicePackageTypeCode, setServicePackageTypeCode] =
    useState<string>("");
  const [appointmentTracking, setAppointmentTracking] = useState<any>();
  const [requestedService, setRequestedService] = useState<WorkshopService>();

  const [isCustomBrand, setIsCustomBrand] = useState<boolean>(false);
  const [isCustomModel, setIsCustomModel] = useState<boolean>(false);

  const [showCancel, setCancel] = useState<boolean>(false);
  const [showNoShowCancel, setNoShowCancel] = useState<boolean>(false);
  const [viewExitModal, setViewExitModal] = useState<boolean>(false);
  const [diagnosticConfirmation, setDiagnosticConfirmation] =
    useState<boolean>(false);
  const [quotationConfirmation, setQuotationConfirmation] =
    useState<boolean>(false);
  const [diagnosticFormMode, setDiagnosticFormMode] = useState<"DIAG" | "QUOT">(
    "DIAG"
  );

  // Reception Confirmation
  const [receptionConfirmation, setReceptionConfirmation] =
    useState<boolean>(false);
  const [coworkers, setCoworkers] = useState<any>([]);
  const [appointmentStatus, setAppointmentStatus] = useState("");

  // Modal
  const [showQuotationApprovalModal, setShowQuotationApprovalModal] =
    useState<boolean>(false);
  const [showAppointmentApprovalModal, setShowAppointmentApprovalModal] =
    useState<boolean>(false);
  const [showCancelAppointment, setShowCancelAppointment] =
    useState<boolean>(false);

  // Reschedule Appointment
  const [showNewAppointmentDate, setShowNewAppointmentDate] =
    useState<boolean>(false);
  const [newAppointmentDate, setNewAppointmentDate] = useState<any>({
    date: moment(new Date()),
    time: "",
  });
  const [appointmentHourList, setAppointmentHourList] = useState<string[]>([]);

  const getClosedDays = () => {
    let closedDaysArray: number[] = [];
    const closedDays = hours.filter((day: any) => day.isOpen === false);

    closedDays?.forEach((day) => {
      closedDaysArray.push(day.dayCode === 7 ? 0 : day.dayCode);
    });
    return closedDaysArray;
  };

  /**
   * Filter quotation items and return them to be sent to API
   */
  const getFilteredQuotationItems = (quotationItems: any[]) => {
    /**
     * Creating objects packages with a new interface for the payload
     */
    const packages = quotationItems
      .filter((item) => item.quotationItemType === "package")
      .map((item) => {
        return {
          Id: item.quotation.id,
          isQuotationApproved: item.quotation.isApproved,
          PackagesLines: item.quotation.lines,
        };
      });

    /**
     * Creating objects services with a new interface for the payload
     */
    const services = quotationItems
      .filter((item) => item.quotationItemType === "service")
      .map((item) => {
        return {
          Id: item.quotation.id,
          isQuotationApproved: item.quotation.isApproved,
          ServicesLines: item.quotation.lines,
        };
      });

    return {
      packages,
      services,
    };
  };

  const calculateCardSubtotals = (quotationItems: any[]): ISubtotal[] => {
    return quotationItems
      .filter((quotationItem) => quotationItem.quotation.isApproved)
      .map((quotationItem): ISubtotal => {
        return {
          label: quotationItem.quotation.lines[0].label,
          amountToDiscount: quotationItem.quotation.lines.reduce(
            (prev: number, current: IQuotationLine) =>
              prev + current.numberOfUnits * current.amountToDiscount,
            0
          ),
          subtotal: quotationItem.quotation.lines.reduce(
            (prev: number, current: IQuotationLine) => prev + current.lineTotal,
            0
          ),
        };
      });
  };

  const validateQuotationItems = (
    quotationItems: any[]
  ): { isValid: boolean; message?: string } => {
    const subtotal = calculateCardSubtotals(quotationItems);

    if (subtotal.length === 0)
      return {
        isValid: false,
        message: "Debe aprobar al menos un servicio para continuar.",
      };

    if (subtotal.some((item: any) => item.subtotal === 0))
      return {
        isValid: false,
        message: "Todos los servicios cotizados deben tener un precio válido.",
      };

    if (
      quotationItems
        .filter((quotationItem) => quotationItem.quotation.isApproved)
        .some((quotationItem) =>
          quotationItem.quotation.lines.some(
            (line: IQuotationLine) => line.pricePerUnit === 0
          )
        )
    )
      return {
        isValid: false,
        message: "Cada linea cotizada debe tener un precio válido.",
      };

    if (
      quotationItems
        .filter((quotationItem) => quotationItem.quotation.isApproved)
        .some((quotationItem) =>
          quotationItem.quotation.lines.some(
            (line: IQuotationLine) => line.label.length === 0
          )
        )
    )
      return {
        isValid: false,
        message: "Debe indicar el concepto de cada linea cotizada.",
      };

    return {
      isValid: true,
      message: "",
    };
  };

  const approveQuotation = async (approvalData: {
    comment?: string;
    imageSupport?: string;
    contactedVia: TContactChannels;
  }) => {
    const validate = validateQuotationItems(quotationItems);

    if (validate.isValid === false) {
      CkMessage({ type: "error", text: validate.message });
      return;
    }

    if (approvalData.contactedVia === undefined) {
      CkMessage({
        type: "error",
        text: "Debe indicar el canal por el cual el cliente fue contactado para la aprobación.",
      });
      return;
    }

    const items = getFilteredQuotationItems(quotationItems);

    /**
     * Creating the payload with QuotationStatus `approved`
     */
    const payload = {
      WorkshopAppointmentTrackingId: appointmentTracking.appointmentTrackingId,
      QuotationStatus: "APPROVED",
      QuotationComment: approvalData.comment || null,
      QuotationApprovedByWorkshop: true,
      QuotationUserContactedVia: approvalData.contactedVia,
      QuotationApprovedByWorkshopSupport: approvalData.imageSupport || null,
      ServicesQuotation: items.services,
      PackagesQuotation: items.packages,
    };

    /**
     * Calling service with the current payload
     */
    AppointmentAPI.approveQuotation(payload)
      .then((response) => {
        if (response.data) {
          CkMessage({
            type: "success",
            text: "La cotización fue aprobada exitosamente.",
          });
          handleConfirm("QUOT");
          reloadAppointmentData();
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al aprobar la cotización.",
          });
        }
      })
      .catch(() =>
        CkMessage({
          type: "error",
          text: "Ups, ocurrio un problema al aprobar la cotización.",
        })
      );
  };

  const updateQuotationStatus = (
    newStatus: TQuotationStatus | "FORCE",
    approvalData?: {
      comment?: string;
      imageSupport?: string;
      contactedVia: TContactChannels;
    }
  ) => {
    if (newStatus === "APPROVED" && approvalData) {
      approveQuotation(approvalData);
    }
    return;
  };

  const getServiceString = () => {
    let servStr: string = "";
    if (dataInfo && dataInfo.extendedProps.serviceTypes) {
      dataInfo.extendedProps.serviceTypes.forEach(
        (serv: any, index: number) => {
          if (index === 0) {
            servStr = serv.serviceTypeName;
          } else {
            servStr = `${servStr}, \n${serv.serviceTypeName}`;
          }
        }
      );
    }
    return servStr;
  };

  const getPackageString = () => {
    let packStr: string = "";
    if (dataInfo && dataInfo.extendedProps.packages) {
      dataInfo.extendedProps.packages.map((pack: any, index: number) => {
        if (index === 0) {
          packStr = pack.servicePackageDesc;
        } else {
          packStr = `${packStr}, ${pack.servicePackageDesc} `;
        }
      });
    }
    return packStr;
  };

  const getPackageTypeString = () => {
    let packStr: string = "";
    if (dataInfo && dataInfo.extendedProps.packages) {
      dataInfo.extendedProps.packages.map((pack: any, index: number) => {
        if (index === 0) {
          packStr = pack.servicePackageTypeDesc;
        } else {
          packStr = `${packStr}, ${pack.servicePackageTypeDesc} `;
        }
      });
    }
    return packStr;
  };

  const exitModal = () => {
    setProgressState(exitState);
    setProgressPercent(0);
    setShowNewAppointmentDate(false);
    setCancel(false);
    setShowAppointmentApprovalModal(false);
    setShowCancelAppointment(false);
    setNoShowCancel(false);
    setIsReceptionFormCompleted(false);
    setIsDiagnosticsFormCompleted(false);
    setIsQuotationFormCompleted(false);
    setIsClientDriver(false);
    setSelectedPackageSet([]);
    setQuotationItems([]);
    setShowQuotationApprovalModal(false);
    //setLoadingAppointmentTracking(true);
    //onClose();
    {
      /* @<AppointmentInfo > onClose
            () => {
            setShowModalInfo(false);
            setEventInfo(null);
            setTimeout(() => {
              checkAccess();
              // fetchAppoitnmentStatusData();
              fetchEventData();
            }, 3000);
          }
        */
    }
  };

  async function handleConfirm(
    state: string | null = null,
    LicensePlate: string | undefined = null,
    km: string | null = null
  ) {
    try {
      const values = dataInfo?.extendedProps;

      const vehicleInfo: {
        Nickname: string;
        TypeCode: string;
        TypeName: string;
        BrandCode: string;
        BrandName: string;
        IsCustomBrand: boolean;
        YearCode: string;
        Year: string;
        ModelCode: string;
        ModelName: string;
        FuelTypeCode: string;
        FuelTypeName: string;
        IsCustomModel: boolean;
        IsEnabled: boolean;
        Km: string | number;
        LicensePlate: string;
        VehicleId: number | "" | null;
      } = {
        Nickname: `${values.vehicle.brandCode.toUpperCase()} - ${values.vehicle.modelCode.toUpperCase()}`,
        TypeCode: values.vehicle.typeCode,
        TypeName: values.vehicle.typeCode,
        BrandCode: values.vehicle.brandCode,
        BrandName: values.vehicle.brandCode,
        IsCustomBrand: isCustomBrand,
        YearCode: values.vehicle.yearCode,
        Year: values.vehicle.yearCode,
        ModelCode: values.vehicle.modelCode,
        ModelName: values.vehicle.modelCode,
        FuelTypeCode: values.vehicle.fuelTypeCode,
        FuelTypeName: values.vehicle.fuelTypeCode,
        IsCustomModel: isCustomBrand ? true : isCustomModel,
        IsEnabled: true,
        Km: state === "RECEP" ? km.toString() : values.vehicle.km.toString(),
        LicensePlate: LicensePlate ? LicensePlate : values.vehicle.licensePlate,
        VehicleId: dataInfo.extendedProps.vehicle.vehicleId,
      };

      const toAdd: Appointment | any = {
        id: dataInfo?.publicId as string,
        statusCode:
          state !== undefined && state !== null && state !== "CLOSTEMP"
            ? state
            : appointmentStatus === "CLOSTEMP"
            ? "CLOS"
            : appointmentStatus,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime,
        detail: "",
        workshopUserId: user?.id as string,
        endConsumer: {
          name: values.endConsumer.name as string,
          lastName: values.endConsumer.lastName,
          phone: values.endConsumer.phone as string,
          email: values.endConsumer.email as string,
        },
        vehicle: vehicleInfo,
      };

      const services = values.serviceTypes.map((service: any) => {
        return { serviceTypeCode: service?.serviceTypeCode };
      });

      const pack = selectedPackageSet.find(
        (a: any) => a?.type === servicePackageTypeCode
      );

      const packageList = [
        {
          WorkshopServicePackageSetId: pack?.servicePackageSetId,
          WorkshopId: workshopId,
          Price: pack?.price,
        },
      ];

      if (packages.length > 0) {
        toAdd.packages = packageList;
        toAdd.serviceTypes = [];
      } else {
        toAdd.serviceTypes = services;
      }

      msgID = Math.random().toString();
      const loadingMessageKey = CkMessage({
        type: "loading",
        text: "Guardando datos...",
      });
      dispatch(
        updateAppointment({ appointment: toAdd, workshopId: workshopId })
      )
        .then((result: any) => {
          CkMessage({
            type: "success",
            text: "Cita actualizada",
            messageToDestroy: loadingMessageKey,
          });
          reloadAppointmentData();
          if (result.payload?.data?.statusCode === "CLOS") {
            dispatch(
              completeAppoinment(result.payload.data.endConsumer.email)
            ).then((r: any) => {
              reloadAppointmentData();
              dispatch(
                checkNotification(result.payload.data.endConsumer.email)
              );
            });
          }
        })
        .catch((e) => {
          CkMessage({
            type: "error",
            text: "Ocurrió un error al actualizar la cita",
            messageToDestroy: loadingMessageKey,
          });
        });
      reloadAppointmentData();
      exitModal();
    } catch (errorInfo) {
      console.error("error", errorInfo);
      CkMessage({
        type: "error",
        text: "Ocurrió un error al actualizar la cita",
      });
    }
  }

  const getMonthByNumber = (index: number) => {
    return months[index];
  };

  const getProductRecommendations = async (
    vehicleId: string,
    servicesCodeArray: string,
    endConsumerId: string,
    servicesPackageList: string
  ) => {
    let products: Array<Product> = [];
    const recProductsRes = await CatalogsAPI.getRecommendedProducts(
      vehicleId,
      servicesCodeArray,
      endConsumerId,
      servicesPackageList
    );
    recProductsRes.data.map((res) =>
      products.push({
        productName: res.productName,
        viscosity:
          res.viscosity === "null" || res.viscosity == null
            ? ""
            : res.viscosity,
        oilType: res.oilType,
        mainBenefit: res.mainBenefit,
        secondaryBenefit: res.secondaryBenefit,
        productDesc: res.productDesc,
        photoUrl: res.photoUrl,
        enabled: true,
        productRecommendationLinks: res.productRecommendationLinks,
      })
    );
    return recProductsRes;
  };

  const updateAppointmentStatus = async (status: string) => {
    let services = dataInfo.extendedProps.serviceTypes;
    let packages = dataInfo.extendedProps.packages;

    if (packages.length === 0) {
      services = services.map((service: any) => {
        return { serviceTypeCode: service.serviceTypeCode };
      });
    }
    const loadingMessageKey = CkMessage({
      type: "loading",
      text: "Actualizando cita...",
    });
    let appointment: Appointment = {
      ...dataInfo.extendedProps,
      id: dataInfo?.publicId,
      statusCode: status,
      detail: "",
    };
    if (packages.length > 0) {
      const packageList = [
        {
          WorkshopServicePackageSetId: packages[0].workshopServicePackageSetId,
          WorkshopId: workshopId,
          Price: packages[0].price,
        },
      ];
      appointment.packages = packageList;
      appointment.serviceTypes = [];
    } else {
      appointment.serviceTypes = services;
    }
    dispatch(
      updateAppointment({
        appointment: appointment,
        workshopId: workshopId,
      })
    )
      .then(async (appointmentUpdated: any) => {
        let servicesCodeArray: string = "";
        let servicesNameArray: string;
        let servicesPackageList: string = "";

        let serv: Array<any> = appointmentUpdated.payload.data.serviceTypes.map(
          (service: any) => service.serviceTypeCode
        );
        let servName: Array<any> =
          appointmentUpdated.payload.data.serviceTypes.map(
            (service: any) => service.serviceTypeName
          );
        let packs: Array<any> = appointmentUpdated.payload.data.packages.map(
          (pack: any) => pack.servicePackageCode
        );

        serv.map((service: string, index: number) => {
          index > 0
            ? (servicesCodeArray = servicesCodeArray + "," + service)
            : (servicesCodeArray = service);
        });
        servName.map((service: string, index: number) => {
          index > 0
            ? (servicesNameArray = servicesNameArray + "," + service)
            : (servicesNameArray = service);
        });
        // setServices(servicesNameArray);
        packs.map((pack, index: number) => {
          index > 0
            ? (servicesPackageList = servicesPackageList + "," + pack)
            : (servicesPackageList = pack);
        });

        const getRecommendations = await getProductRecommendations(
          dataInfo.extendedProps.vehicle.vehicleId,
          servicesCodeArray,
          dataInfo.extendedProps.endConsumer.id,
          servicesPackageList
        );
        setProduct(getRecommendations.data);
        setProductSize(getRecommendations.data.length);
        CkMessage({
          type: "success",
          text: "Cita actualizada",
          messageToDestroy: loadingMessageKey,
        });
        if (
          (serv.includes("FUELINJ") ||
            serv.includes("SPAOILF") ||
            serv.includes("OILCMUL") ||
            serv.includes("FLUIDLVL") ||
            serv.includes("HFENADJ") ||
            getRecommendations.data.length > 0) &&
          status !== "CANCE"
        ) {
          setShowProductRecModal(true);
          reloadAppointmentData();
          exitModal();
          setShowCancelAppointment(false);
        } else {
          setShowProductRecModal(false);
          setProductSize(0);
          reloadAppointmentData();
          exitModal();
          setShowCancelAppointment(false);
        }
      })
      .catch(() =>
        CkMessage({
          type: "error",
          text: "Ocurrió un error al actualizar la cita",
          messageToDestroy: loadingMessageKey,
        })
      );
  };

  const confirmAppointment = (
    newStatus: string,
    comment: string | undefined = ""
  ) => {
    setShowAppointmentApprovalModal(false);
    const validationCode = dataInfo?.extendedProps?.lastValidationCode;

    if (newStatus !== "APPROVED" || comment === "" || !validationCode) {
      CkMessage({
        type: "error",
        text: "Ups, ocurrio un problema al confirmar la cita.",
      });
      return;
    }

    const loadingMessageKey = CkMessage({
      type: "loading",
      text: "Confirmando cita.",
    });
    AppointmentAPI.confirm(dataInfo?.publicId, validationCode, comment)
      .then((response) => {
        if (response.data) {
          CkMessage({
            type: "success",
            text: "La cita fue confirmada.",
            messageToDestroy: loadingMessageKey,
          });
          reloadAppointmentData();
          exitModal();
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al confirmar la cita.",
            messageToDestroy: loadingMessageKey,
          });
        }
      })
      .catch(() =>
        CkMessage({
          type: "error",
          text: "Ups, ocurrio un problema al confirmar la cita.",
          messageToDestroy: loadingMessageKey,
        })
      );
  };

  const rescheduleAppointment = async () => {
    const servicesInfo = dataInfo.extendedProps.serviceTypes;
    let services = [];
    if (packages.length === 0) {
      services = servicesInfo.map((service: string) => {
        return { serviceTypeCode: service.serviceTypeCode };
      });
    }
    const appoinmentDuration = moment(dataInfo.extendedProps.endDateTime).diff(
      moment(dataInfo.extendedProps.startDateTime),
      "minutes"
    );
    let date: moment.Moment = newAppointmentDate.date!;
    let time = moment(newAppointmentDate.time, "HH:mmZ");

    if (time.utcOffset() / 60 < 0 && time.hour() + time.utcOffset() / 60 < 0) {
      date = date.subtract(1, "d");
    } else if (
      time.utcOffset() / 60 > 0 &&
      time.hour() + time.utcOffset() / 60 > 24
    ) {
      date = date.add(1, "d");
    }
    date.hour(time.hour());
    date.minute(time.minute());

    const appointment: Appointment = {
      ...dataInfo.extendedProps,
      id: dataInfo?.publicId,
      startDateTime: date.toISOString(),
      endDateTime: date.add(appoinmentDuration, "m").toISOString(),
      statusCode: "CREAT",
      serviceTypes: services,
    };

    const loadingMessageKey = CkMessage({
      type: "loading",
      text: "Actualizando cita...",
    });

    setLoadingReschedule(true);
    dispatch(
      updateAppointment({
        appointment: appointment,
        workshopId: workshopId,
      })
    )
      .then((response: any) => {
        if (response) {
          // window.location.reload();
          // setLoadingReschedule(false);
          reloadAppointmentData();
          exitModal();
        }
        CkMessage({
          type: "success",
          text: "Cita actualizada",
          messageToDestroy: loadingMessageKey,
        });
      })
      .catch((err: any) => {
        CkMessage({
          type: "error",
          text: "Ocurrió un error al actualizar la cita",
          messageToDestroy: loadingMessageKey,
        });
        setLoadingReschedule(false);
      });
    // reloadAppointmentData();
    // exitModal();
  };

  const translateServiceToQuotationItem = ({
    packages,
    services,
  }: {
    packages: any[];
    services: any[];
  }) => {
    const checkedPackages = packages.map((pack) => {
      const quotationTitle = `${pack.servicePackageSet.servicePackage.servicePackageDesc} - ${pack.servicePackageSet.servicePackageType.servicePackageTypeDesc}`;
      /**
       * Empty item
       */
      const quotation: IQuotationItem = {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            label: quotationTitle,
            description: "", // Must be retrieved from backend
            pricePerUnit: 0, // Must be retrieved from backend
            numberOfUnits: 1,
            discount: 0,
            amountToDiscount: 0,
            lineTotal: 0, // calculate if price is provided on backend
          },
        ],
      };

      /**
       * check if package have quotation
       */
      if ("quotation" in pack) {
        quotation.id = pack.quotation.id || 0;
        quotation.isApproved = pack.quotation.isQuotationApproved;
        if (
          pack.quotation.packagesLines &&
          pack.quotation.packagesLines.length > 0
        )
          quotation.lines = [...pack.quotation.packagesLines];
      }

      return {
        ...pack,
        quotationTitle: quotationTitle,
        quotationItemType: "package",
        quotation: quotation,
      };
    });
    const checkedServices = services.map((service) => {
      const quotationTitle = service.service.serviceName;
      /**
       * Empty item
       */
      const quotation: IQuotationItem = {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            label: quotationTitle,
            description: "", // Must be retrieved from backend
            pricePerUnit: 0, // Must be retrieved from backend
            numberOfUnits: 1,
            discount: 0,
            amountToDiscount: 0,
            lineTotal: 0, // calculate if price is provided on backend
          },
        ],
      };

      /**
       * check if service have quotation
       */
      if ("quotation" in service) {
        quotation.id = service.quotation.id || 0;
        quotation.isApproved = service.quotation.isQuotationApproved;
        if (
          service.quotation.servicesLines &&
          service.quotation.servicesLines.length > 0
        )
          quotation.lines = [...service.quotation.servicesLines];
      }

      return {
        ...service,
        quotationTitle: quotationTitle,
        quotationItemType: "service",
        quotation: quotation,
      };
    });
    setQuotationItems([...checkedPackages, ...checkedServices]);
  };

  const setProgress = (appointmentData: any, trackingData: any) => {
    let quotationTitle: string | undefined = undefined;
    let quotationOnClick: Function | undefined = undefined;
    // Check if tracking data exists
    if (trackingData && "quotationStatus" in trackingData) {
      if ((trackingData.quotationStatus as TQuotationStatus) === "APPROVED") {
        quotationTitle = "Cotización aprobada";
        /**
         * Check if we need to display the modal, with the data provided by the workshop,
         * when the workshop approves the quotation on behalf the user
         */
        if (trackingData.quotationApprovedByWorkshop === true) {
          quotationTitle = "Cotización confirmada por otro medio";
          quotationOnClick = () => {
            setOpenQuotationInfoModal(true);
          };
        }
      }
      if (
        (trackingData.quotationStatus as TQuotationStatus) ===
        "WAITING_APPROVAL"
      ) {
        quotationTitle = "Cotización por aprobar";
      }
    }

    const progress: IStateProgress[] = [...progressState];

    const recepDate = appointmentData.appointmentHistoryStatus.filter(
      (status: IappointmentHistoryStatus) => status.statusCode === "RECEP"
    )[0];
    const RECEP: IStateProgress = {
      id: "RECEP",
      passed: false,
      title: "Recibido",
      date: recepDate?.statusModifiedDate || "",
    };

    const diagDate = appointmentData.appointmentHistoryStatus.filter(
      (status: IappointmentHistoryStatus) => status.statusCode === "DIAG"
    )[0];
    const DIAG: IStateProgress = {
      id: "DIAG",
      passed: false,
      title: "Diagnosticado",
      date: diagDate?.statusModifiedDate || "",
    };

    const quotDate = appointmentData.appointmentHistoryStatus.filter(
      (status: IappointmentHistoryStatus) => status.statusCode === "QUOT"
    )[0];
    const QUOT: IStateProgress = {
      id: "QUOT",
      passed: false,
      title: trackingData && quotationTitle ? quotationTitle : "Cotizado",
      date: quotDate?.statusModifiedDate || "",
      ...(quotationOnClick ? { onClick: quotationOnClick } : {}),
    };
    if (DIAG.date === "" && QUOT.date !== "") {
      DIAG.date = QUOT.date;
    }

    const progDate = appointmentData.appointmentHistoryStatus.filter(
      (status: IappointmentHistoryStatus) => status.statusCode === "PROG"
    )[0];
    const PROG: IStateProgress = {
      id: "PROG",
      passed: false,
      title: "Reparando",
      date: progDate?.statusModifiedDate || "",
    };

    const READY: IStateProgress = {
      id: "READY",
      passed: false,
      title: "Terminado",
      date:
        appointmentData?.appointmentHistoryStatus.filter(
          (status: IappointmentHistoryStatus) => status.statusCode === "READY"
        )[0]?.statusModifiedDate || "",
    };

    const CLOS: IStateProgress = {
      id: "CLOS",
      passed: false,
      title: "Entregado",
      date:
        appointmentData?.appointmentHistoryStatus.filter(
          (status: IappointmentHistoryStatus) => status.statusCode === "CLOS"
        )[0]?.statusModifiedDate || "",
    };

    switch (appointmentData.statusCode) {
      case "RECEP":
        RECEP.passed = true;
        windowSize > 1200 ? setProgressPercent(11) : setProgressPercent(14);
        break;
      case "DIAG":
        RECEP.passed = true;
        DIAG.passed = true;
        windowSize > 1200 ? setProgressPercent(30) : setProgressPercent(32);
        break;
      case "QUOT":
        RECEP.passed = true;
        DIAG.passed = true;
        setProgressPercent(50);
        if (
          trackingData?.quotationStatus === "WAITING_APPROVAL" ||
          trackingData?.quotationStatus === "APPROVED"
        )
          QUOT.passed = true;
        break;
      case "PROG":
        RECEP.passed = true;
        DIAG.passed = true;
        QUOT.passed = true;
        PROG.passed = true;
        setProgressPercent(69);
        break;
      case "READY":
        RECEP.passed = true;
        DIAG.passed = true;
        QUOT.passed = true;
        PROG.passed = true;
        READY.passed = true;
        windowSize > 1200 ? setProgressPercent(88) : setProgressPercent(86);
        break;
      case "CLOS":
        RECEP.passed = true;
        DIAG.passed = true;
        QUOT.passed = true;
        PROG.passed = true;
        READY.passed = true;
        CLOS.passed = true;
        setProgressPercent(100);
        break;
      default:
        setProgressPercent(0);
        break;
    }

    progress[0] = RECEP;
    progress[1] = DIAG;
    progress[2] = QUOT;
    progress[3] = PROG;
    progress[4] = READY;
    progress[5] = CLOS;

    setProgressState([...progress]);
  };

  const getWorkingHoursByDay = (momentIndex: number) => {
    let timeList = [];
    const index = momentIndex === 0 ? 7 : momentIndex;
    const workDay = hours.find((day) => index === day.dayCode);
    if (workDay?.isOpen) {
      const initialTime = moment(workDay?.openTime, "HH:mmZZ");
      const closingTime = moment(workDay?.closeTime, "HH:mmZZ");
      if (closingTime < initialTime) {
        closingTime.add(1, "d");
      }
      const hourAmmount = moment
        .duration(closingTime.diff(initialTime))
        .asHours();

      let time = initialTime;
      let timeValues = [time.hour(), time.minutes()];
      for (let i = 0; i < hourAmmount; i++) {
        if (timeValues[1] === 0) {
          timeList.push(
            `${timeValues[0] < 10 ? "0" + timeValues[0] : timeValues[0]}:${
              "0" + timeValues[1]
            }`
          );
          time.add(30, "m");
          timeValues = [time.hour(), time.minutes()];
        }
        if (timeValues[1] === 30) {
          timeList.push(
            `${timeValues[0] < 10 ? "0" + timeValues[0] : timeValues[0]}:${
              timeValues[1]
            }`
          );
          time.add(30, "m");
          timeValues = [time.hour(), time.minutes()];
        }
      }
    }
    setAppointmentHourList(timeList);
  };

  //
  useEffect(() => {
    if (dataInfo) {
      const customServices = dataInfo.extendedProps?.customServices;

      if (customServices && customServices.length > 0) {
        // requestedService
        setRequestedService(customServices[0]);

        setReasons(
          Array.isArray(customServices[0]?.serviceCases) &&
            customServices[0].serviceCases.length > 0
            ? customServices[0].serviceCases
                .map((serviceCase) => serviceCase.serviceCaseDesc)
                .join(", ")
            : "-"
        );
      } else {
        const pack = dataInfo.extendedProps.packages;
        let serv: Array<any> = dataInfo.extendedProps.serviceTypes.map(
          (service: WorkshopService) => service.serviceTypeCode
        );

        if (pack.length > 0) {
          const code = pack[0].servicePackageCode;
          const packages = packagesList.find((a) => Object.keys(a)[0] === code);
          setSelectedPackageSet(packages !== undefined ? packages[code] : []);
        } else {
          setServicesSelected([...serv]);
        }

        if (
          dataInfo.extendedProps.appointmentFailures &&
          dataInfo.extendedProps.appointmentFailures.length > 0
        ) {
          let strValue: string = "";
          dataInfo.extendedProps.appointmentFailures.map((reason: any) => {
            if (reason.srvcTypeFailRelatedId !== "IDK0000") {
              strValue = strValue + `• ${reason.srvcTypeFailRelatedDesc}\n`;
            }
          });

          dataInfo.extendedProps.appointmentFailures.map((reason: any) => {
            if (reason.srvcTypeFailRelatedId === "IDK0000") {
              strValue =
                strValue +
                `${
                  dataInfo.extendedProps.appointmentFailures.length > 1
                    ? `\n`
                    : ``
                } Comentario: ${reason.comment}`;
            }
          });

          setReasons(strValue);
        } else {
          setReasons("");
        }
      }
    }
  }, [dataInfo]);

  useEffect(() => {
    if (dataInfo) {
      const pack = dataInfo.extendedProps.packages;
      setPackages([...pack]);

      let servicesCodeArray: string = "";
      let servicesNameArray: string;
      let servicesPackageList: string = "";

      let serv: Array<any> = dataInfo.extendedProps.serviceTypes.map(
        (service: any) => service.serviceTypeCode
      );
      let servName: Array<any> = dataInfo.extendedProps.services.map(
        (service: any) => service.serviceTypeName
      );
      let packs: Array<any> = dataInfo.extendedProps.packages.map(
        (pack: any) => pack.servicePackageCode
      );

      Promise.resolve()
        .then(() => {
          serv.map((service: string, index: number) => {
            index > 0
              ? (servicesCodeArray = servicesCodeArray + "," + service)
              : (servicesCodeArray = service);
          });
          servName.map((service: string, index: number) => {
            index > 0
              ? (servicesNameArray = servicesNameArray + "," + service)
              : (servicesNameArray = service);
          });
          packs.map((pack, index: number) => {
            index > 0
              ? (servicesPackageList = servicesPackageList + "," + pack)
              : (servicesPackageList = pack);
          });
        })
        .finally(() => {
          let temp: Array<Product> = [];
          CatalogsAPI.getRecommendedProducts(
            dataInfo.extendedProps.vehicle.vehicleId,
            servicesCodeArray,
            dataInfo.extendedProps.endConsumer.id,
            servicesPackageList
          )
            .then((response) => {
              response.data.map((res) =>
                temp.push({
                  productName: res.productName,
                  viscosity:
                    res.viscosity === "null" || res.viscosity == null
                      ? ""
                      : res.viscosity,
                  oilType: res.oilType,
                  mainBenefit: res.mainBenefit,
                  secondaryBenefit: res.secondaryBenefit,
                  productDesc: res.productDesc,
                  photoUrl: res.photoUrl,
                  enabled: true,
                  productRecommendationLinks: res.productRecommendationLinks,
                })
              );
            })
            .finally(() => {
              setProduct([...temp]);
              setProductSize(temp.length);
            });
        });

      if (pack.length > 0) {
        const code = pack[0].servicePackageCode;
        const packages = packagesList.find((a) => Object.keys(a)[0] === code);
        setSelectedPackageSet(packages !== undefined ? packages[code] : []);
        setServicePackageTypeCode(pack[0].servicePackageTypeCode);
      }
      setAppointmentStatus(dataInfo.extendedProps.statusCode);
    }
  }, [dataInfo]);

  useEffect(() => {
    if (vehicleBrands) {
      const otherBrand: VehicleCatalog = {
        code: "custom_brand",
        desc: "Otra marca",
      };
      let allBrands = vehicleBrands;
      if (allBrands.filter((x) => x.code === "custom_brand")?.length === 0)
        allBrands.push(otherBrand);
      setVehicleBrands(allBrands);
      if (
        dataInfo &&
        dataInfo.extendedProps.vehicle &&
        dataInfo.extendedProps.vehicle.brandCode?.startsWith("custom_brand")
      ) {
        setIsCustomBrand(true);
      } else {
        setIsCustomBrand(false);
      }
    } else {
      setIsCustomBrand(false);
    }
  }, [vehicleBrands]);

  useEffect(() => {
    if (vehicleModels) {
      const otherModel: VehicleCatalog = {
        code: "custom_model",
        desc: "Otro modelo",
      };
      let allModels = vehicleModels;
      if (allModels.filter((x) => x.code === "custom_model")?.length === 0)
        allModels.push(otherModel);
      setVehicleModels(allModels);
      if (
        dataInfo &&
        dataInfo.extendedProps.vehicle &&
        dataInfo.extendedProps.vehicle.modelCode?.startsWith("custom_model")
      ) {
        setIsCustomModel(true);
      } else {
        setIsCustomModel(false);
      }
    } else {
      setIsCustomModel(false);
    }
  }, [vehicleModels]);

  useEffect(() => {
    const getCoworkers = async () => {
      if (workshopId && workshopId !== "") {
        const result = await fetchAllCoworkers(workshopId);
        if (result.status === 200) setCoworkers([...result.data]);
      }
    };
    getCoworkers();
  }, [workshopId]);

  useEffect(() => {
    if (appointmentTracking?.sameDriver) setIsClientDriver(true);

    if (appointmentTracking?.appointmentTrackingId > 0)
      setIsReceptionFormCompleted(true);

    if (
      (appointmentTracking?.packagesRequired.length > 0 ||
        appointmentTracking?.servicesRequired.length > 0) &&
      appointmentStatus !== "RECEP"
    )
      setIsDiagnosticsFormCompleted(true);

    if (appointmentTracking?.quotationStatus === "APPROVED")
      setIsQuotationFormCompleted(true);
    else setIsQuotationFormCompleted(false);
  }, [appointmentTracking]);

  useEffect(() => {
    if (dataInfo) {
      const dateMoment = moment(
        new Date(dataInfo?.extendedProps?.startDateTime)
      );

      getWorkingHoursByDay(dateMoment.day());
      setNewAppointmentDate({ date: dateMoment, time: "" });
      setProgress(dataInfo?.extendedProps, appointmentTracking);
    }
  }, [dataInfo, appointmentTracking]);

  useEffect(() => {
    if (dataInfo) {
      setShowMessage(true);
      AppointmentAPI.getTrackingReception(dataInfo?.publicId)
        .then((response) => {
          // destructure data, giving appointmentTracking as alias.
          const { data: appointmentTracking } = response || { data: undefined };
          // if Response is invalid or invoice doesn't exist, we return nothing
          if (
            appointmentTracking === undefined ||
            appointmentTracking?.invoiceNo === undefined ||
            appointmentTracking?.invoiceNo === null
          ) {
            setLoadingAppointmentTracking(false);
            return;
          }
          // Set appointmentTracking for usage
          setAppointmentTracking(appointmentTracking);
          translateServiceToQuotationItem({
            packages: Array.isArray(appointmentTracking.packagesRequired)
              ? appointmentTracking.packagesRequired
              : [],
            services: Array.isArray(appointmentTracking.servicesRequired)
              ? appointmentTracking.servicesRequired
              : [],
          });
          const folio =
            appointmentTracking.invoiceNo + "-" + appointmentTracking.version;
          setInvoiceNo(folio);
          setLoadingAppointmentTracking(false);
        })
        .catch((error) => console.error(error));
    }
  }, [dataInfo]);

  useEffect(() => {
    if (
      !loadingAppointment &&
      !error &&
      errorMessage === "updated" &&
      showMessage
    ) {
      setShowMessage(false);
      CkMessage({ type: "success", text: "Cita Actualizada" });
      msgID = undefined;
      // GOOGLE TAG
      if (appointmentEdited) {
        let services: any[] = appointmentEdited.serviceTypes;
        if (appointmentEdited?.statusCode === "CANCE") {
          gtmAddLayer({
            appointment_ID: appointmentEdited.id,
            service_types: services
              .map((service: Service) => {
                return service.serviceCode;
              })
              .join(","),
            workshop_ID: workshopId,
            event: "cancel_appointment",
          });
        } else {
          gtmAddLayer({
            appointment_ID: appointmentEdited.id,
            service_types: services
              .map((service: Service) => {
                return service.serviceCode;
              })
              .join(","),
            workshop_ID: workshopId,
            appointment_status: appointmentEdited?.statusCode,
            event: "change_status",
          });
        }
      }
      //onConfirm(); ()=>{} on <AppointmentInfo /> definition
    }
  }, [loadingAppointment, error, errorMessage]);

  return (
    <Fragment>
      <WheelLoader spinning={loadingAppointmentTracking} />
      {!loadingAppointmentTracking && (
        <>
          <div className="appointment-modal">
            <AppointmentDetailsHeader
              invoiceNo={invoiceNo}
              brandName={dataInfo?.extendedProps?.vehicle?.brandName}
              modelName={dataInfo?.extendedProps?.vehicle?.modelName}
              year={dataInfo?.extendedProps?.vehicle?.year}
              startDateTime={dataInfo?.extendedProps?.startDateTime}
              windowSize={windowSize}
              statusAppointment={appointmentStatus}
              statusAppointmentName={dataInfo.extendedProps.statusName}
              isReceptionFormCompleted={isReceptionFormCompleted}
              isQuotationFormCompleted={isQuotationFormCompleted}
              isDiagnosticsFormCompleted={isDiagnosticsFormCompleted}
              progressState={progressState}
              progressPercent={progressPercent}
              handleQuotationFormButton={() => {
                // setQuotationConfirmation(true);
                setDiagnosticConfirmation(true);
                setDiagnosticFormMode("QUOT");
              }}
              handleReceptionFormButton={() => {
                setReceptionConfirmation(true);
              }}
              handleDiagnosticFormButton={() => {
                setDiagnosticConfirmation(true);
                setDiagnosticFormMode("DIAG");
              }}
              packageString={getPackageString() || ""}
              serviceString={getServiceString() || ""}
            />

            {/* ACTIONS ROW */}
            <AppointmentDetailsActions
              appointmentStatus={appointmentStatus}
              startDateTime={dataInfo?.extendedProps?.startDateTime}
              setShowCancelAppointment={setShowCancelAppointment}
              setNoShowCancel={setNoShowCancel}
              setShowNewAppointmentDate={setShowNewAppointmentDate}
              setShowAppointmentApprovalModal={setShowAppointmentApprovalModal}
              updateAppointmentStatus={updateAppointmentStatus}
              handleReceptionFormButton={() => {
                setReceptionConfirmation(true);
              }}
              handleDiagnosticFormButton={() => {
                setDiagnosticConfirmation(true);
                setDiagnosticFormMode("DIAG");
              }}
              handleQuotationFormButton={() => {
                // setQuotationConfirmation(true);
                setDiagnosticConfirmation(true);
                setDiagnosticFormMode("QUOT");
              }}
              appointmentTracking={appointmentTracking}
              setShowQuotationApprovalModal={setShowQuotationApprovalModal}
              handleConfirm={handleConfirm}
              statusAppointment={appointmentStatus}
              isReceptionFormCompleted={isReceptionFormCompleted}
              isQuotationFormCompleted={isQuotationFormCompleted}
              isDiagnosticsFormCompleted={isDiagnosticsFormCompleted}
            />
            {/* ACTIONS ROW END */}

            <AppointmentDetailsProgress
              startDateTime={dataInfo?.extendedProps?.startDateTime}
              statusAppointment={appointmentStatus}
              progressState={progressState}
              progressPercent={progressPercent}
            />

            {/* DATE ROW */}
            <AppointmentDetailsTable
              startDateTime={dataInfo?.extendedProps?.startDateTime}
              invoiceNo={invoiceNo}
              name={dataInfo?.extendedProps?.endConsumer?.name}
              lastName={dataInfo?.extendedProps?.endConsumer?.lastName}
              email={dataInfo?.extendedProps?.endConsumer?.email}
              phone={dataInfo?.extendedProps?.endConsumer?.phone}
              reasons={reasons || "-"}
              isReceptionFormCompleted={isReceptionFormCompleted}
              isClientDriver={isClientDriver}
              setShowProductRecModal={setShowProductRecModal}
              showPackages={
                requestedService
                  ? requestedService.workshopServiceType === "MAN"
                  : packages && packages.length > 0
              }
              showServices={
                requestedService
                  ? requestedService.workshopServiceType === "REP"
                  : !!packages === false || packages.length === 0
              }
              packageString={
                requestedService &&
                requestedService.workshopServiceType === "MAN"
                  ? requestedService.workshopServiceName
                  : getPackageString() || "-"
              }
              packageTypeString={
                requestedService &&
                requestedService.workshopServiceType === "MAN"
                  ? tierCodeToDesc(
                      requestedService?.serviceTiers[0]
                        ?.servicePackageTypeCode || ""
                    )
                  : getPackageTypeString() || "-"
              }
              serviceString={
                requestedService &&
                requestedService.workshopServiceType === "REP"
                  ? requestedService.workshopServiceName
                  : getServiceString() || "-"
              }
              productSize={productSize}
            />
            {/* SERVICES ROW END */}
          </div>

          <ConfirmationModal
            visible={showCancel}
            title="Cancelar Cita"
            message="¿Estás seguro de que deseas cancelar la cita de tu calendario y notificar a tu cliente?"
            icon={<CalendarDeleteIcon />}
            onOk={() => {
              handleConfirm("CANCE");
            }}
            onCancel={() => setCancel(false)}
          />

          <ConfirmationModal
            visible={viewExitModal}
            onOk={() => {
              setViewExitModal(false);
              reloadAppointmentData();
              exitModal();
            }}
            onCancel={() => setViewExitModal(false)}
            title="Cerrar sin guardar"
            message="Tiene cambios sin guardar, ¿está seguro que desea salir?"
            icon={<CalendarDeleteIcon />}
          />

          <QuotationFormModal
            quotationConfirmation={quotationConfirmation}
            setQuotationConfirmation={setQuotationConfirmation}
            appointmentInfo={dataInfo ? dataInfo.extendedProps : {}}
            hours={hours}
            appointmentId={dataInfo ? dataInfo?.publicId! : ""}
            workshopId={workshopId}
            services={workshopServiceList}
            packages={packagesList}
            handleConfirm={handleConfirm}
            coworkers={coworkers}
          />

          <DiagnosticFormModal
            diagnosticConfirmation={diagnosticConfirmation}
            setDiagnosticConfirmation={setDiagnosticConfirmation}
            appointmentInfo={dataInfo ? dataInfo.extendedProps : {}}
            hours={hours}
            appointmentId={dataInfo ? dataInfo?.publicId! : ""}
            workshopId={workshopId}
            services={workshopServiceList}
            packages={packagesList}
            handleConfirm={handleConfirm}
            coworkers={coworkers}
            mode={diagnosticFormMode}
          />

          {receptionConfirmation && (
            <NEWReceptionFormModal
              receptionConfirmation={receptionConfirmation}
              setReceptionConfirmation={setReceptionConfirmation}
              appointmentInfo={dataInfo ? dataInfo.extendedProps : {}}
              hours={hours}
              appointmentId={dataInfo ? dataInfo?.publicId! : ""}
              workshopId={workshopId}
              services={workshopServiceList}
              packages={packagesList}
              handleConfirm={handleConfirm}
              coworkers={coworkers}
              statusAppointment={appointmentStatus}
            />
          )}

          <QuotationApprovalModal
            showQuotationApprovalModal={showQuotationApprovalModal}
            setShowQuotationApprovalModal={setShowQuotationApprovalModal}
            updateQuotationStatus={updateQuotationStatus}
            appointmentId={dataInfo ? dataInfo?.publicId! : ""}
          />

          {/* Appointment approval modal */}
          <ConfirmAppointmentModal
            showModal={showAppointmentApprovalModal}
            setShowModal={setShowAppointmentApprovalModal}
            updateStatus={confirmAppointment}
          />
          {/* Appointment approval modal */}

          <ConfirmationModal
            visible={showCancelAppointment}
            onOk={() => {
              handleConfirm("CANCE");
              reloadAppointmentData();
              exitModal();
            }}
            onCancel={() => {
              setShowCancelAppointment(false);
            }}
            title="Cancelar cita"
            message="¿Estás seguro de que deseas cancelar la cita?"
            subMessage="Asegúrate de comunicarte con tu cliente para hacérselo saber."
            icon={<CalendarDeleteIcon style={{ width: "100px" }} />}
          />

          <ConfirmationModal
            visible={showNoShowCancel}
            onOk={() => {
              const values = dataInfo?.extendedProps;
              console.log("values: ", values);
              handleConfirm("CANCE");
              reloadAppointmentData();
              exitModal();
            }}
            onCancel={() => {
              setNoShowCancel(false);
            }}
            title="¿Deseas cancelar la cita?"
            message="Antes de cancelar, asegúrate de informar a tu cliente sobre este cambio. Cancelar una cita puede tener un gran impacto para ambas partes."
            //subMessage="Asegúrate de comunicarte con tu cliente para hacérselo saber."
            icon={<CalendarDeleteIcon style={{ width: "100px" }} />}
            okText="Sí, cancelar cita"
            cancelText="No, mantener cita"
          />

          {/* {showNewAppointmentDate && (
            <RescheduleAppointmentAD
              showNewAppointmentDate={showNewAppointmentDate}
              setShowNewAppointmentDate={setShowNewAppointmentDate}
              getClosedDays={getClosedDays}
              newAppointmentDate={newAppointmentDate}
              setNewAppointmentDate={setNewAppointmentDate}
              getMonthByNumber={getMonthByNumber}
              appointmentHourList={appointmentHourList}
              rescheduleAppointment={rescheduleAppointment}
            />
          )} */}

          {showNewAppointmentDate && (
            <RescheduleModal
              loadingReschedule={loadingReschedule}
              startDateTime={dataInfo?.extendedProps?.startDateTime}
              showNewAppointmentDate={showNewAppointmentDate}
              setShowNewAppointmentDate={setShowNewAppointmentDate}
              getClosedDays={getClosedDays}
              newAppointmentDate={newAppointmentDate}
              setNewAppointmentDate={setNewAppointmentDate}
              getMonthByNumber={getMonthByNumber}
              appointmentHourList={appointmentHourList}
              rescheduleAppointment={rescheduleAppointment}
              getWorkingHoursByDay={getWorkingHoursByDay}
              hours={hours}
            />
          )}

          <ProductRecommendationModal
            showOli={showProductRecModal}
            products={product}
            onCancel={() => setShowProductRecModal(false)}
          />

          <QuotationInfoModal
            open={openQuotationInfoModal}
            setOpen={setOpenQuotationInfoModal}
            appointmentTracking={appointmentTracking}
            method={"Whatsapp"}
            comments={"Esto es un comentario de prueba para el componente."}
            confirmationImage={
              "https://bcx-storage.be.xom.cloud/ws-images-container/1701120734987_dogecoin.png"
            }
          />
        </>
      )}
    </Fragment>
  );
};
