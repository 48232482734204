import React, { Fragment } from "react";
import moment from "moment";
import { CkButton, CkDivider } from "../../../CkUI";
type ActionListStatus = {
  primary?: {
    label: string;
    func: Function;
  };
  secondary?:
    | {
        label: string;
        func: Function;
      }
    | {};
  tertiary?: {
    label: string;
    func: Function;
  };
};
type ActionList = {
  PEND: ActionListStatus;
  CREAT: ActionListStatus;
  APPO: ActionListStatus;
  RECEP: ActionListStatus;
  DIAG: ActionListStatus;
  QUOT: ActionListStatus;
  PROG: ActionListStatus;
  READY: ActionListStatus;
  CLOS: ActionListStatus;
  CANCE: ActionListStatus;
};
interface IProps {
  appointmentStatus: string;
  startDateTime: string;
  setShowCancelAppointment: Function;
  setNoShowCancel: Function;
  setShowNewAppointmentDate: Function;
  setShowAppointmentApprovalModal: Function;
  updateAppointmentStatus: Function;
  handleReceptionFormButton: Function;
  handleDiagnosticFormButton: Function;
  handleQuotationFormButton: Function;
  appointmentTracking: { quotationStatus: string };
  setShowQuotationApprovalModal: Function;
  handleConfirm: Function;
  isReceptionFormCompleted: boolean;
  isQuotationFormCompleted: boolean;
  isDiagnosticsFormCompleted: boolean;
  statusAppointment: string;
}

export const AppointmentDetailsActions: React.FC<IProps> = ({
  appointmentStatus,
  startDateTime,
  setShowCancelAppointment,
  setNoShowCancel,
  setShowNewAppointmentDate,
  setShowAppointmentApprovalModal,
  setShowQuotationApprovalModal,
  updateAppointmentStatus,
  handleReceptionFormButton,
  handleDiagnosticFormButton,
  handleQuotationFormButton,
  appointmentTracking,
  handleConfirm,
  statusAppointment,
  isReceptionFormCompleted,
  isQuotationFormCompleted,
  isDiagnosticsFormCompleted,
}) => {
  type actionListKey = keyof typeof actionList;

  const actionList: ActionList | any = {
    CREAT: {
      primary: {
        label: "El cliente confirmó por otro medio",
        func: () => {
          setShowAppointmentApprovalModal(true);
        },
      },
      secondary: {
        label: "Proponer otro horario",
        func: () => {
          setShowNewAppointmentDate(true);
        },
      },
      tertiary: {
        label: "Cancelar cita",
        func: () => {
          setShowCancelAppointment(true);
        },
      },
    },
    PEND: {
      primary: {
        label: "Confirmar",
        func: async () => {
          updateAppointmentStatus("APPO");
        },
      },
      secondary: {
        label: "Proponer otro horario",
        func: async () => {
          setShowNewAppointmentDate(true);
        },
      },
      tertiary: {
        label: "Cancelar cita",
        func: async () => {
          setShowCancelAppointment(true);
        },
      },
    },
    APPO: {
      primary: {
        label: "Recibir vehículo",
        func: () => {
          handleReceptionFormButton();
        },
      },
      secondary: {
        label: "El cliente no llegó",
        func: async () => {
          setNoShowCancel(true);
        },
      },
    },
    RECEP: {
      primary: {
        label: "Iniciar diagnóstico",
        func: () => {
          handleDiagnosticFormButton();
        },
      },
    },
    DIAG: {
      primary: {
        label: "Iniciar cotización",
        func: () => {
          handleQuotationFormButton();
        },
      },
    },
    QUOT: {
      primary:
        appointmentTracking?.quotationStatus === "APPROVED"
          ? {
              label: "Iniciar reparación",
              func: () => {
                handleConfirm("PROG");
              },
            }
          : {
              label: "Editar cotización",
              func: () => {
                handleQuotationFormButton();
              },
            },
      secondary:
        appointmentTracking?.quotationStatus === "APPROVED"
          ? null
          : {
              label: "El cliente confirmó por otro medio",
              func: () => {
                setShowQuotationApprovalModal(true);
              },
            },
    },
    PROG: {
      primary: {
        label: "Confirmar reparación terminada",
        func: () => {
          handleConfirm("READY");
        },
      },
    },
    READY: {
      primary: {
        label: "Confirmar entrega",
        func: () => {
          handleConfirm("CLOS");
        },
      },
    },
    CLOS: {},
    CANCE: {},
  };
  
  return (
    <div className="appointment-details-actions">
      {/* ACTIONS ROW */}
      {appointmentStatus !== "" &&
        (moment(new Date(new Date(startDateTime))) <= moment().endOf("day") ||
          appointmentStatus === "CREAT" ||
          appointmentStatus === "PEND") && (
          <div className="actions">
            <div>
            {actionList[appointmentStatus as actionListKey].primary && (
              <CkButton
                onClick={
                  actionList[appointmentStatus as actionListKey]?.primary?.func
                }
              >
                {actionList[appointmentStatus as actionListKey]?.primary?.label}
              </CkButton>
            )}
            {actionList[appointmentStatus as actionListKey]?.secondary && (
              <CkButton
                onClick={
                  actionList[appointmentStatus as actionListKey]?.secondary
                    ?.func
                }
                variant="secondary"
              >
                {
                  actionList[appointmentStatus as actionListKey]?.secondary
                    ?.label
                }
              </CkButton>
            )}
            </div>
            <div>
              {actionList[appointmentStatus as actionListKey]?.tertiary && (
                <CkButton
                  onClick={
                    actionList[appointmentStatus as actionListKey]?.tertiary
                      ?.func
                  }
                  block={false}
                  variant="link"
                >
                  {
                    actionList[appointmentStatus as actionListKey]?.tertiary
                      ?.label
                  }
                </CkButton>
              )}
            </div>
          </div>
        )}
      {appointmentStatus !== "PEND" &&
        appointmentStatus !== "CREAT" &&
        moment(new Date(startDateTime)) > moment().endOf("day") && (
          <div className="actions">
            <div>
              <CkButton
                onClick={() => setShowNewAppointmentDate(true)}
                variant="secondary"
              >
                Proponer otro horario
              </CkButton>
              <CkButton
                variant="link"
                block={false}
                onClick={() => setShowCancelAppointment(true)}
              >
                Cancelar cita
              </CkButton>
            </div>
          </div>
        )}
      {/* ACTIONS ROW END */}
      {(isReceptionFormCompleted ||
        isDiagnosticsFormCompleted ||
        isQuotationFormCompleted) && (
          <div className="formats">
            {(statusAppointment === "RECEP" || isReceptionFormCompleted) && (
              <CkButton
                onClick={handleReceptionFormButton}
                variant="link"
                block={false}
              >
                Formato de recepción
              </CkButton>
            )}
            {(statusAppointment === "DIAG" || isDiagnosticsFormCompleted) && (
              <CkButton
                onClick={handleDiagnosticFormButton}
                variant="link"
                block={false}
              >
                Formato de diagnóstico
              </CkButton>
            )}
            {(statusAppointment === "QUOT" || isQuotationFormCompleted) && (
              <CkButton
                onClick={handleQuotationFormButton}
                variant="link"
                block={false}
              >
                Formato de cotización
              </CkButton>
            )}
          </div>
        )}
      <CkDivider />
    </div>
  );
};
