import React from "react";
import moment from "moment";
import { AppointmentProgress } from "./AppointmentProgress";
import { CkDivider } from "../../../CkUI";
interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
}
interface IProps {
  startDateTime: string;
  statusAppointment: string;
  progressState: IStateProgress[];
  progressPercent: number;
}
export const AppointmentDetailsProgress: React.FC<IProps> = ({
  startDateTime,
  progressState,
  progressPercent,
  statusAppointment,
}) => {
  if (
    statusAppointment === "CANCE" ||
    statusAppointment === "PEND" ||
    statusAppointment === "CREAT"
  )
    return null;

  // @ts-ignore
  return (
    <div className="appointment-details-progress">
      <h2>Progreso</h2>
      <div className="appointment-status-container">
        {statusAppointment !== "PEND" &&
          statusAppointment !== "CREAT" &&
          moment(new Date(startDateTime)) > moment().endOf("day") && (
            <p className="warning">
              La cita podrá continuar con el flujo una vez llegue el día de la
              cita.
            </p>
          )}
        {moment(new Date(new Date(startDateTime))) <= moment().endOf("day") && (
          <AppointmentProgress
            state={progressState}
            percent={progressPercent}
          />
        )}
      </div>
      <CkDivider />
    </div>
  );
};
