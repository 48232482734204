import React from "react";
import { formatShortDate, formatShortTime } from "../../Utilities/Dates";
import { CkNavigationButton } from "../../../CkUI";
import { ArrowLeftOutlined } from "@ant-design/icons";
interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
}
interface IProps {
  brandName: string;
  modelName: string;
  year: string;
  startDateTime: string;
  windowSize: number;
  statusAppointment: string;
  statusAppointmentName: string;
  isReceptionFormCompleted: boolean;
  isQuotationFormCompleted: boolean;
  isDiagnosticsFormCompleted: boolean;
  progressState: IStateProgress[];
  progressPercent: number;
  handleQuotationFormButton: Function;
  handleReceptionFormButton: Function;
  handleDiagnosticFormButton: Function;
  packageString: string;
  serviceString: string;
  invoiceNo: string;
}
export const AppointmentDetailsHeader: React.FC<IProps> = ({
  brandName,
  modelName,
  year,
  startDateTime,
  statusAppointment,
  statusAppointmentName,
  packageString,
  serviceString,
  invoiceNo
}) => {
  // @ts-ignore
  return (
    <div className="appointment-details-header">
      <CkNavigationButton 
        direction="back"
        icon={"arrow-left-outlined"}
      >
        Regresar
      </CkNavigationButton>
      <h2 className="appointment-car-name">
        {`${brandName} ${modelName}  ${year}`.toLowerCase()}
      </h2>
      <p className="service-title">
        {packageString && packageString}
        {serviceString && serviceString}
      </p>
      <div
        className="field-row"
      >
        <div className="field">
          <p className="label">Fecha:</p>
          <p className="value">
            {formatShortDate(startDateTime)}
          </p>
        </div>
        <div className="field">
          <p className="label">Hora:</p>
          <p className="value">
            {formatShortTime(startDateTime)}
          </p>
        </div>
        <div className="field">
          <p className="label">Estatus de cita:</p>
          <p className="value appointment-status">
            <div style={{ backgroundColor: `var(--${statusAppointment}-color)`}} />
            {statusAppointmentName}
          </p>
        </div>
        <div className="field">
          <p className="label">Orden de servicio número:</p>
          <p className="value invoice-number">
            {invoiceNo || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};
