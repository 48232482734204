import React, { Fragment } from "react";
import { Col, Row } from "antd";
import { formatShortDate, formatShortTime } from "../../Utilities/Dates";
import { CkButton, CkCheckbox } from "../../../CkUI";

interface IStateProgress {
  id: string;
  passed: boolean;
  title: string;
  date: string;
}
interface IProps {
  name: string;
  lastName: string;
  email: string;
  phone: string;
  reasons: string;
  isReceptionFormCompleted: boolean;
  isClientDriver: boolean;
  setShowProductRecModal: Function;
  showPackages: boolean;
  showServices: boolean;
  packageString: string;
  packageTypeString: string;
  serviceString: string;
  productSize: number;
}
export const AppointmentDetailsTable: React.FC<IProps> = ({
  name,
  lastName,
  email,
  phone,
  reasons,
  isReceptionFormCompleted,
  isClientDriver,
  setShowProductRecModal,
  showPackages,
  showServices,
  packageString,
  packageTypeString,
  serviceString,
  productSize,
}) => (
  <div className="appointment-details-table">
    <div className="field-row">
      <div className="field">
        <p className="label">Nombre(s) del cliente:</p>
        <p className="value">{name}</p>
      </div>
      <div className="field">
        <p className="label">Apellido(s) del cliente:</p>
        <p className="value">{lastName}</p>
      </div>
    </div>
    {/* COSTUMER FIRST ROW END */}

    {/* COSTUMER SECOND ROW */}
    <div className="field-row">
      <div className="field">
        <p className="label">Email:</p>
        <p className="value">{email}</p>
      </div>
      <div className="field">
        <p className="label">Teléfono celular:</p>
        <p className="value">{phone}</p>
      </div>
    </div>
    {/* COSTUMER SECOND ROW END */}

    {/* SERVICES ROW */}
    {showPackages && (
      <>
        <div className="field-row">
          <div className="field">
            <p className="label">Paquete:</p>
            <p className="value">{packageString || "-"}</p>
          </div>
          <div className="field">
            <p className="label">Tipo de paquete:</p>
            <p className="value">{packageTypeString || "-"}</p>
          </div>
        </div>
        {productSize > 0 && (
          <div className="field-row">
            <div className="field">
              <CkButton
                variant="link"
                onClick={() => setShowProductRecModal(true)}
              >
                Ver recomendaciones de aceite
              </CkButton>
              {/* </Tooltip> */}
            </div>
          </div>
        )}
      </>
    )}
    {/*/!packages || (packages.length === 0}*/}
    {showServices && (
      <>
        <div className="field-row">
          <div className="field">
            <p className="label">Servicio(s):</p>
            <p className="value services">{serviceString || "-"}</p>
          </div>

          {productSize > 0 && (
            <div className="field">
              <CkButton
                variant="link"
                onClick={() => setShowProductRecModal(true)}
              >
                Ver recomendaciones de aceite
              </CkButton>
              {/* </Tooltip> */}
            </div>
          )}
        </div>
        <div className="field-row">
          <div className="field">
            <p className="label">Fallas reportadas:</p>
            <div className="value reasons">{reasons || "-"}</div>
          </div>
        </div>
      </>
    )}

    {/* SERVICES ROW END */}
  </div>
);
